<template>
    <v-form ref="form">
        <v-row align="center">
            <v-col class="pt-0 pb-0" cols="12" sm="6">
                <v-switch
                    v-model="productData.displayOnCoatinoWebsite"
                    class="pa-1 mt-0"
                    :label="statusLabel"
                    :readonly="readonly"
                    color="primary"
                    hide-details
                    inset
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { plainObjectsEqual } from '@/utils'

export default {
    name: 'ProductMarketingForm',
    props: {
        value: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            productData: {},
            productIsArchived: false,
            rules: {
                segmentation: [v => !!v || this.$t('product.segmentation.errors.required')],
                description: [v => !v || v.length <= 1000 || this.$t('product.description.errors.maxLength')]
            }
        }
    },
    computed: {
        ...mapGetters('product', ['segmentations']),
        ...mapGetters('auth', ['isAdministrator', 'isGlobalTechnicalExpert', 'allowedActions']),
        allSegmentations() {
            if (!this.segmentations || !this.segmentations.length) {
                return []
            }
            if (this.isAdministrator || this.isGlobalTechnicalExpert) {
                return this.segmentations.concat([
                    { divider: true },
                    { value: 'CANCEL', text: this.$t('product.segmentations.CANCEL') }
                ])
            }
            return this.segmentations
        },
        statusLabel() {
            const label = this.$t('product.displayOnWebsite.label')
            const value = this.productData.displayOnCoatinoWebsite ? this.$t('common.yes') : this.$t('common.no')
            return `${label}: ${value}`
        },
        shouldSegmentationBeReadonly() {
            return this.productIsArchived && this.allowedActions.RESTORE_FROM_ARCHIVE ? false : this.readonly
        }
    },
    watch: {
        value: {
            deep: true,
            handler: function (newValue, oldValue) {
                if (!plainObjectsEqual(newValue, oldValue)) {
                    this.productData = Object.assign({}, newValue)
                }
            }
        },
        productData: {
            deep: true,
            handler: function (newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    mounted() {
        this.productData = Object.assign({}, this.value)
        this.productIsArchived = this.productData.segmentation && this.productData.segmentation === 'CANCEL'
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        }
    }
}
</script>
