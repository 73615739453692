






















































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
    computed: {
        ...mapGetters('auth', ['allowedActions', 'isAdministrator'])
    }
})
class ProductTableNavigation extends Vue {
    @Prop({ type: Boolean, default: false })
    readonly someTableIsDirty!: boolean
    // mapGetters
    readonly isAdministrator!: boolean
    readonly allowedActions!: { [key: string]: boolean }

    private selectedTab: string = ''

    get showGlobalTechTab() {
        return this.allowedActions.EDIT_GLOBAL_TECHNICAL_RATING || this.isAdministrator
    }

    get showRegulatoryTab() {
        return this.allowedActions.EDIT_REGULATORY_INFORMATION || this.isAdministrator
    }

    get showMarketingTab() {
        return this.allowedActions.EDIT_MARKETING_RATING || this.isAdministrator
    }

    get showCommunicationTab() {
        return this.allowedActions.EDIT_PICTURES_AND_VIDEOS || this.isAdministrator
    }

    get showMarketSegmentTab() {
        return this.allowedActions.EDIT_MARKET_SEGMENT_RATING || this.isAdministrator
    }

    @Watch('selectedTab')
    onTabChanged(value: string) {
        this.$emit('tabChanged', value)
    }
}

export default ProductTableNavigation
