<template>
    <v-expansion-panel v-if="showThisTable" :disabled="disabled">
        <v-expansion-panel-header :disable-icon-rotate="showHeaderIcon">
            {{ table.title }} {{ isDirty ? `- ${$t('product.changed')}` : '' }}
            <template v-if="showHeaderIcon" v-slot:actions>
                <v-icon v-if="!localData._isFilledCompletely" color="warning" class="ma-1">
                    {{ svgIncomplete }}
                </v-icon>
                <v-icon v-else size="32px" color="green">
                    {{ svgComplete }}
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="fields && Object.keys(fields).length">
            <v-select
                v-if="withSegments"
                v-model="localSelectedSegmentId"
                :items="translatedSegments"
                :label="$t('product.segment.label')"
                :disabled="disabled"
                :readonly="readonly"
            />
            <v-container fluid grid-list-xl>
                <v-layout wrap align-center>
                    <v-flex v-for="field of fields" :key="field.name" :class="viewModeSelect" d-flex>
                        <v-select
                            v-if="field.enumValues"
                            v-model="localData[field.name]"
                            :items="translateFieldEnum(field.enumValues)"
                            :label="field.title"
                            :readonly="readonly"
                            required
                        />
                        <v-select
                            v-else-if="field.type === 'boolean'"
                            v-model="localData[field.name]"
                            :items="translateFieldEnum([true, false])"
                            :label="field.title"
                            :readonly="readonly"
                            required
                        />
                        <v-text-field
                            v-else
                            v-model="localData[field.name]"
                            :label="field.title"
                            :readonly="readonly"
                            required
                        />
                    </v-flex>
                </v-layout>
            </v-container>
            <v-container fluid grid-list-xl>
                <v-layout wrap align-center>
                    <v-flex :class="viewModeLegend">
                        <v-card v-if="legend" :class="compareMode ? '' : 'mr-4'">
                            <v-card-title v-t="'product.legend.headline'" />
                            <v-card-text>
                                <ul class="legend pa-0">
                                    <li v-for="(entry, index) in legend" :key="index" class="mb-2" v-html="entry" />
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex class="flex-column align-self-stretch" :class="viewModeButtonBar">
                        <v-select
                            v-if="
                                numberOfFieldsWithEnum > 1 &&
                                tableGlobalEnumItems.length &&
                                !readonly &&
                                tableName !== 'specialIngredient'
                            "
                            v-model="oneValueForAll"
                            :items="tableGlobalEnumItems"
                            :label="$t('product.setAllTo.label')"
                            :append-outer-icon="svgForward"
                            @click:append-outer="updateAllFields"
                        />
                        <v-layout v-if="!readonly" justify-end>
                            <v-btn tile color="primary" class="mr-4" @click="save">
                                {{ $t(`product.button.save`) }}
                            </v-btn>
                            <v-btn tile outlined color="primary" class="mr-4" @click="reset">
                                {{ $t(`product.button.cancel`) }}
                            </v-btn>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
            {{ $t(`product.noData`) }}
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { mdiAlertCircleOutline, mdiCheck, mdiForward } from '@mdi/js'
import { extractAndDisplayError, plainObjectsEqual } from '@/utils'

export default {
    name: 'ProductTablePanel',
    props: {
        product: {
            type: Object,
            required: true
        },
        tableName: {
            type: String,
            required: true
        },
        compareMode: {
            type: Boolean,
            default: true
        },
        segmentId: {
            type: [Number, String],
            default: null
        },
        technologyId: {
            type: [Number, String],
            default: null
        },
        withSegments: {
            type: Array,
            default: null
        },
        outerSelectedMarketingSegment: {
            type: [Number, String],
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        svgComplete: mdiCheck,
        svgForward: mdiForward,
        svgIncomplete: mdiAlertCircleOutline,
        oneValueForAll: null,
        localData: {},
        isDirty: false,
        localSelectedSegmentId: null
    }),
    computed: {
        table() {
            return this.$store.getters['product/table'](this.tableName) || false
        },
        showThisTable() {
            if (this.table && this.table.onlyForSegments && this.table.onlyForSegments.length) {
                return this.table.onlyForSegments.indexOf(this.segmentId) > -1
            }
            return true
        },
        fields() {
            return this.table ? [].concat(this.table.fields).sort((a, b) => a.title.localeCompare(b.title)) : []
        },
        fieldsIndexed() {
            return this.fields.reduce((coll, field) => {
                coll[field.name] = field
                return coll
            }, {})
        },
        numberOfFieldsWithEnum() {
            return this.fields.filter(field => !!field.enumValues || field.type === 'boolean').length
        },
        tableGlobalEnumItems() {
            return this.table && this.table.enumValues ? this.translateFieldEnum(this.table.enumValues) : []
        },
        legend() {
            return this.table ? this.table.legend : false
        },
        tableData() {
            const table = this.product.tables[this.tableName]
            if (!table || !table.data) {
                return false
            }
            if (this.hasSegment) {
                const data = table.data[this.segmentId]
                if (!data) {
                    return this.getEmptyData()
                } else if (this.hasTechnology) {
                    return data[this.technologyId] || this.getEmptyData()
                } else {
                    return data
                }
            } else if (this.withSegments) {
                return table.data[this.localSelectedSegmentId] || this.getEmptyData()
            }
            this.table.fields.map(f => {
                if (!table.data[f.name] && f.type !== 'boolean') {
                    table.data[f.name] = null
                }
            })
            return table.data
        },
        hasSegment() {
            return this.segmentId || String(this.segmentId) === '0'
        },
        hasTechnology() {
            return !!this.technologyId
        },
        translatedSegments() {
            return (this.withSegments || []).map(seg => {
                return {
                    value: seg.value,
                    text: this.$t(`product.marketSegments.${seg.text}`)
                }
            })
        },
        showHeaderIcon() {
            return !this.withSegments && this.table && this.table.inCompletenessCheck
        },
        viewModeSelect() {
            return this.compareMode ? 'pb-0 pt-0 xs12' : 'xs12 sm6 md4 lg3'
        },
        viewModeLegend() {
            return this.compareMode ? 'pt-0 pl-0 pr-0' : 'xs12 sm6 md8 lg9'
        },
        viewModeButtonBar() {
            return this.compareMode ? 'pt-0 xs12' : 'xs12 sm6 md4 lg3'
        }
    },
    watch: {
        tableData: {
            immediate: true,
            handler: function (newValue) {
                this.localData = Object.assign({}, newValue)
            }
        },
        localData: {
            deep: true,
            handler: function () {
                this.isDirty = !plainObjectsEqual(this.localData, this.tableData)
                this.$emit('dirtyChanged', this.isDirty)
            }
        },
        localSelectedSegmentId(segId) {
            this.$emit('segmentIdChanged', segId)
        },
        outerSelectedMarketingSegment(segId) {
            this.localSelectedSegmentId = segId
        }
    },
    mounted() {
        if (this.withSegments) {
            this.localSelectedSegmentId = this.withSegments[0].value
        }
    },
    methods: {
        getEmptyData() {
            return this.fields.reduce((coll, field) => {
                coll[field.name] = null
                return coll
            }, {})
        },
        translateFieldEnum(fieldValues) {
            const tableModifier = this.tableName === 'countryRegulation' ? `${this.tableName}.` : ''
            return [null].concat(fieldValues.filter(f => f !== 'UNSET') || []).map(v => {
                return { value: v, text: this.$t(`product.values.${v ? tableModifier : ''}${v}`) }
            })
        },
        updateAllFields() {
            this.fields.forEach(field => {
                if (field.enumValues || (field.type && field.type === 'boolean')) {
                    this.localData[field.name] = this.oneValueForAll
                }
            })
            this.oneValueForAll = null
        },
        save() {
            this.$store
                .dispatch('product/updateProductData', {
                    tableName: this.tableName,
                    segmentId: this.withSegments ? this.localSelectedSegmentId : this.segmentId,
                    technologyId: this.technologyId,
                    data: this.localData,
                    productId: this.product.base.id
                })
                .then(() => this.$noty.success(this.$t('product.updateProduct.successMessage'), { timeout: 2000 }))
                .catch(err => extractAndDisplayError(err, this.$noty))
        },
        reset() {
            this.localData = this.tableData ? Object.assign({}, this.tableData) : false
        }
    }
}
</script>

<style lang="scss">
ul.legend {
    list-style-type: none;
}
</style>
