<template>
    <v-form ref="form">
        <v-textarea
            v-model="productData.description"
            :label="$t('product.description.label')"
            :rules="rules.description"
            :readonly="readonly"
            :disabled="readonly"
            :counter="readonly ? false : 1000"
            maxlength="1000"
            validate-on-blur
        />
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { plainObjectsEqual } from '@/utils'

export default {
    name: 'ProductGlobalTechForm',
    props: {
        value: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            productData: {},
            rules: {
                description: [v => !v || v.length <= 1000 || this.$t('product.description.errors.maxLength')]
            }
        }
    },
    computed: {
        ...mapGetters('auth', ['isAdministrator', 'isGlobalTechnicalExpert', 'allowedActions']),
        statusLabel() {
            const label = this.$t('product.displayOnWebsite.label')
            const value = this.productData.displayOnCoatinoWebsite ? this.$t('common.yes') : this.$t('common.no')
            return `${label}: ${value}`
        }
    },
    watch: {
        value: {
            deep: true,
            handler: function (newValue, oldValue) {
                if (!plainObjectsEqual(newValue, oldValue)) {
                    this.productData = Object.assign({}, newValue)
                }
            }
        },
        productData: {
            deep: true,
            handler: function (newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    mounted() {
        this.productData = Object.assign({}, this.value)
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        }
    }
}
</script>
