<template>
    <v-form ref="form">
        <v-text-field
            v-model="productData.name"
            :label="$t('product.name.label')"
            :rules="rules.name"
            :readonly="readonly"
            :disabled="readonly"
            :hint="readonly ? '' : $t('product.name.hint')"
            :counter="readonly ? false : 255"
            maxlength="255"
            validate-on-blur
        />
        <v-row align="center">
            <v-col class="pb-0" cols="12" sm="6">
                <v-text-field
                    v-model="productData.id"
                    :label="$t('product.id.label')"
                    :rules="rules.id"
                    :readonly="!isCreate"
                    :disabled="readonly"
                    maxlength="20"
                    validate-on-blur
                />
            </v-col>
            <v-col class="pb-0" cols="12" sm="6">
                <v-text-field
                    v-model="productData.msdsId"
                    :label="$t('product.msdsId.label')"
                    :rules="rules.msdsId"
                    :readonly="readonly"
                    :disabled="readonly"
                    maxlength="20"
                    validate-on-blur
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pb-0" cols="12" sm="6">
                <v-select
                    v-model="productData.productGroupWebsite"
                    :items="productGroups"
                    :label="$t('product.productGroupWebsite.label')"
                    :rules="rules.productGroup"
                    :readonly="readonly"
                    :multiple="false"
                    validate-on-blur
                />
            </v-col>
            <v-col class="pb-0" cols="12" sm="6">
                <v-select
                    v-model="productData.segmentation"
                    :items="allSegmentations"
                    :label="$t('product.segmentation.label')"
                    :readonly="shouldSegmentationBeReadonly"
                    :multiple="false"
                    validate-on-blur
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { plainObjectsEqual } from '@/utils'

export default {
    name: 'ProductBaseForm',
    props: {
        value: {
            type: Object,
            required: true
        },
        isCreate: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            productData: {},
            productIsArchived: false,
            rules: {
                name: [
                    v => !!v || this.$t('product.name.errors.required'),
                    v => (v && v.length <= 255) || this.$t('product.name.errors.maxLength')
                ],
                id: [
                    v => !!v || this.$t('product.id.errors.required'),
                    v => /^\d+$/.test(v) || this.$t('product.id.errors.onlyDigits'),
                    v => Number(v) > 0 || this.$t('product.id.errors.minimum'),
                    v => (v && String(v).length <= 20) || this.$t('product.id.errors.maxLength')
                ],
                msdsId: [
                    v => !!v || this.$t('product.msdsId.errors.required'),
                    v => /^\d+$/.test(v) || this.$t('product.msdsId.errors.onlyDigits'),
                    v => Number(v) > 0 || this.$t('product.msdsId.errors.minimum'),
                    v => (v && String(v).length <= 20) || this.$t('product.msdsId.errors.maxLength')
                ],
                productGroup: [v => !!v || this.$t('product.productGroupWebsite.errors.required')]
            }
        }
    },
    computed: {
        ...mapGetters('product', ['segmentations']),
        ...mapGetters('auth', ['isAdministrator', 'isSAPExpert', 'allowedActions']),
        ...mapState('product', ['productGroups']),
        allSegmentations() {
            if (!this.segmentations || !this.segmentations.length) {
                return []
            }
            if (
                !this.isCreate &&
                (this.isAdministrator || this.isSAPExpert || this.productData.segmentation === 'CANCEL')
            ) {
                return this.segmentations.concat([
                    { divider: true },
                    { value: 'CANCEL', text: this.$t('product.segmentations.CANCEL') }
                ])
            }
            return this.segmentations
        },
        shouldSegmentationBeReadonly() {
            return this.productIsArchived && this.allowedActions.RESTORE_FROM_ARCHIVE ? false : this.readonly
        }
    },
    watch: {
        value: {
            deep: true,
            handler: function (newValue, oldValue) {
                if (!plainObjectsEqual(newValue, oldValue)) {
                    this.productData = Object.assign({}, newValue)
                }
            }
        },
        productData: {
            deep: true,
            handler: function (newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    mounted() {
        this.productData = Object.assign({}, this.value)
        this.productIsArchived = this.productData.segmentation && this.productData.segmentation === 'CANCEL'
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        }
    }
}
</script>
