<template>
    <v-form ref="form" class="product--media-form" :class="isCompareMode ? 'compare' : ''">
        <h2 v-t="'product.media.images'" class="headline my-2" />
        <template v-if="mediaLinks && mediaLinks.length">
            <span v-for="(mediaLink, i) in mediaLinks" :key="`${mediaLink.type}-${i}`">
                <v-row>
                    <v-col :cols="isCompareMode ? 10 : 12" :md="isCompareMode ? 10 : 6" class="py-0">
                        <v-text-field
                            v-model="mediaLink.link"
                            :label="$t('product.media.link')"
                            :readonly="readonly"
                            :rules="[textRule(i)]"
                            validate-on-blur
                        />
                    </v-col>
                    <v-col :cols="isCompareMode ? 5 : 6" :md="isCompareMode ? 5 : 2" class="py-0">
                        <v-text-field
                            v-model="mediaLink.titleTag"
                            :label="$t('product.media.title')"
                            :readonly="readonly"
                            :rules="[textRule(i)]"
                            validate-on-blur
                        />
                    </v-col>
                    <v-col :cols="isCompareMode ? 5 : 6" :md="isCompareMode ? 5 : 2" class="py-0">
                        <v-text-field
                            v-model="mediaLink.altTag"
                            :label="$t('product.media.alt')"
                            :readonly="readonly"
                            :rules="[textRule(i)]"
                            validate-on-blur
                        />
                    </v-col>
                    <v-col md="2">
                        <media-link-assign-button
                            v-if="mediaLink.productId"
                            :product-id="mediaLink.productId"
                            :media-link-id="mediaLink.id"
                            :disabled="isDirty"
                        />
                    </v-col>
                </v-row>
                <v-divider v-if="isCompareMode" class="pb-4" />

                <v-divider v-if="!isCompareMode && i === 4" />
                <h2 v-if="i === 4" v-t="'product.media.videos'" class="headline my-2" />
            </span>
        </template>
    </v-form>
</template>

<script>
import MediaLinkAssignButton from './MediaLinkAssignButton'
import { mapState } from 'vuex'

export default {
    name: 'ProductMediaForm',
    components: {
        MediaLinkAssignButton: MediaLinkAssignButton
    },
    props: {
        productId: {
            type: Number,
            required: true
        },
        value: {
            type: Array,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            originMediaLinks: [],
            mediaLinkState: [],
            images: [],
            videos: [],
            isDirty: false,
            textRule: index => v => {
                const coll = this.mediaLinks
                const entry = coll ? coll[index] || null : null
                /* eslint-disable */
                return !entry ||
                        (!entry.link || !!(entry.link && v.trim())) &&
                        (!entry.titleTag || !!(entry.titleTag && v.trim())) &&
                        (!entry.altTag || !!(entry.altTag && v.trim())) ||
                        this.$t('product.media.requiredError')
            }
        }
    },
    computed: {
        ...mapState('product', ['currentProducts']),
        isCompareMode: state => {
            return state.currentProducts && state.currentProducts.length > 1
        },
        mediaLinks() {
            const i = this.images.map(e => {
                e.type = 'image'
                return e
            })
            const v = this.videos.map(e => {
                e.type = 'video'
                return e
            })
            return [].concat(i).concat(v)
        },
        rawMediaLinks() {
            return []
                .concat(this.images)
                .concat(this.videos)
                .filter(this.removeEmptyMediaLinks)
        }
    },
    watch: {
        value: {
            deep: true,
            handler: function() {
                this.mediaLinkState = JSON.parse(JSON.stringify(this.value))
            }
        },
        currentProducts: {
            deep: true,
            handler: function(newValue) {
                const newMediaLinks = newValue.find(el => el.base.id === this.productId).base.mediaLinks
                const oldMediaLinks = this.originMediaLinks.filter(this.removeEmptyMediaLinks)
                if (JSON.stringify(newMediaLinks).localeCompare(JSON.stringify(oldMediaLinks)) !== 0) {
                    this.extractMediaLinks()
                    this.onUpdate()
                }
            }
        },
        originMediaLinks: {
            deep: true,
            handler: function() {
                this.extractMediaLinks()
            }
        },
        rawMediaLinks: {
            deep: true,
            handler: function(newValue) {
                const comparableOriginMediaLinks = this.originMediaLinks
                    .filter(this.removeEmptyMediaLinks)
                    .sort((a, b) => a.id - b.id)
                const comparableMediaLinks = newValue.filter(this.removeEmptyMediaLinks).sort((a, b) => a.id - b.id)

                this.isDirty =
                    JSON.stringify(comparableMediaLinks).localeCompare(JSON.stringify(comparableOriginMediaLinks)) !== 0
                this.$emit('dirtyChanged', this.isDirty)
                this.$emit('input', this.mediaLinks)
            }
        }
    },
    mounted() {
        this.originMediaLinks = JSON.parse(JSON.stringify(this.value))
        this.mediaLinkState = this.originMediaLinks
        this.extractMediaLinks()
    },
    methods: {
        extractMediaLinks(isReset) {
            const images = []
            const videos = []
            const values = isReset
                ? JSON.parse(JSON.stringify(this.originMediaLinks))
                : JSON.parse(JSON.stringify(this.mediaLinkState))
            if (values && Array.isArray(values)) {
                values.forEach(m => {
                    if (m.type === 'image') {
                        images.push(m)
                    } else if (m.type === 'video') {
                        videos.push(m)
                    }
                })
            }
            while (images.length < 5) {
                images.push({ link: '', titleTag: '', altTag: '', type: 'image' })
            }
            while (videos.length < 5) {
                videos.push({ link: '', titleTag: '', altTag: '', type: 'video' })
            }
            this.images = JSON.parse(JSON.stringify(images))
            this.videos = JSON.parse(JSON.stringify(videos))
        },
        validate() {
            return this.$refs.form.validate()
        },
        removeEmptyMediaLinks(el) {
            return (
                (el.link && el.link.length > 0) ||
                (el.titleTag && el.titleTag.length > 0) ||
                (el.altTag && el.altTag.length > 0)
            )
        },
        onReset() {
            this.extractMediaLinks(true)
            this.validate()
        },
        onUpdate() {
            this.originMediaLinks = JSON.parse(JSON.stringify(this.mediaLinkState))
            this.extractMediaLinks()
        }
    }
}
</script>
<style>
.product--media-form.compare .v-input__slot {
    margin-bottom: 0;
}
</style>
