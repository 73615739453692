<template>
    <div>
        <ProductErrorBlock v-if="fetchProductError" :error="fetchProductError" />
        <v-container v-else :class="isCompareMode ? 'container--fluid' : ''">
            <v-row>
                <v-col
                    v-for="product in currentProducts"
                    :key="'product-header-' + product.base.id"
                    cols="12"
                    :sm="12 / currentProducts.length"
                >
                    <product-header :product="product" :compare-mode="isCompareMode" />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0 pt-0">
                    <product-table-navigation
                        :some-table-is-dirty="dirtyTablesForProducts.length > 0"
                        @tabChanged="selectTab"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-for="product in currentProducts"
                    :key="'product-body-' + product.base.id"
                    cols="12"
                    class="pt-0"
                    :sm="12 / currentProducts.length"
                >
                    <product-form
                        :product="product"
                        :selected-tab="selectedTab"
                        :selected-panel="selectedPanel"
                        :outer-selected-segment="selectedSegment"
                        :outer-selected-technology="selectedTechnology"
                        :outer-selected-marketing-segment="selectedMarketingSegment"
                        :compare-mode="isCompareMode"
                        @selectPanel="selectPanel"
                        @transitTableIsDirty="dirtyTablesChanged"
                        @segmentChanged="selectedSegment = $event"
                        @technologyChanged="selectedTechnology = $event"
                        @marketingSegmentChanged="selectedMarketingSegment = $event"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ProductHeader from '../components/ProductHeader'
import ProductTableNavigation from '../components/ProductTableNavigation'
import ProductErrorBlock from '../components/ProductErrorBlock'

import ProductForm from '../components/ProductForm'
import store from '@/store'
import { mapState } from 'vuex'

const onRouted = (to, from, next) => {
    const ids = to.params.id
        .toString()
        .split(',')
        .filter(el => el.length && el.length > 0)
    store.commit('product/setCurrentProductIds', ids)
    store.dispatch('product/fetchCurrentProducts').finally(() => next())
}

export default {
    name: 'Products',
    components: {
        ProductHeader,
        ProductTableNavigation,
        ProductForm,
        ProductErrorBlock
    },
    data: function () {
        return {
            selectedTab: 'tab-marketing',
            selectedPanel: null,
            dirtyTablesForProducts: [],
            selectedSegment: 0,
            selectedTechnology: 0,
            selectedMarketingSegment: 0
        }
    },
    computed: {
        ...mapState('product', ['currentProducts', 'fetchProductError']),
        isCompareMode() {
            return this.currentProducts && this.currentProducts.length > 1
        }
    },
    beforeRouteEnter: onRouted,
    beforeRouteUpdate: onRouted,
    destroyed: function () {
        return this.$store.commit('product/clearCurrentProduct')
    },
    methods: {
        selectTab(tabIdentifier) {
            this.selectedTab = tabIdentifier
            this.selectedPanel = null
        },
        selectPanel(panelIdentifier) {
            if (typeof panelIdentifier !== 'undefined') {
                this.selectedPanel = panelIdentifier
            } else {
                this.selectedPanel = null
            }
        },
        dirtyTablesChanged(event) {
            const { productId, isDirty } = event
            if (isDirty && !this.dirtyTablesForProducts.includes(productId)) {
                this.dirtyTablesForProducts.push(productId)
            }
            if (!isDirty) {
                this.dirtyTablesForProducts = this.dirtyTablesForProducts.filter(el => el !== productId)
            }
        }
    }
}
</script>
