<template>
    <ProductErrorBlock v-if="fetchProductError" :error="fetchProductError" />
    <v-card v-else-if="showIsArchivedWarning" color="error" tile dark max-width="600" class="mx-auto">
        <v-card-text class="white--text text-center">
            <div v-t="'product.isArchived.headline'" class="headline mb-2" />
            <div v-t="'product.isArchived.text'" class="centered--text" />
        </v-card-text>
    </v-card>
    <div v-else-if="productData">
        <v-tabs v-model="selectedTab" show-arrows height="0">
            <v-tab-item value="tab-basic">
                <v-dialog v-model="showArchiveWarning" persistent max-width="320">
                    <v-card>
                        <v-card-title v-t="'product.archiveWarning.headline'" class="headline" />
                        <v-card-text v-t="'product.archiveWarning.text'" />
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn
                                v-t="'product.archiveWarning.no'"
                                color="primary"
                                tile
                                @click="showArchiveWarning = false"
                            />
                            <v-btn
                                v-t="'product.archiveWarning.yes'"
                                color="primary"
                                text
                                @click="
                                    showArchiveWarning = false
                                    update('base', true)
                                "
                            />
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-card class="mt-2">
                    <v-card-title>
                        {{ $t('product.tabs.basic.headline') }}
                    </v-card-title>
                    <v-card-text>
                        <product-base-form ref="baseForm" v-model="productData.base" :readonly="isBasicTabReadonly" />
                    </v-card-text>
                    <v-card-actions v-if="!isBasicTabReadonly || allowSegmentationUnarchiveOnChange">
                        <v-layout justify-end row>
                            <v-btn
                                v-t="'product.button.update'"
                                :disabled="isUpdatingProduct"
                                :loading="isUpdatingProduct"
                                color="primary"
                                class="mr-4"
                                tile
                                @click="update('base')"
                            />
                            <v-btn
                                v-t="'product.button.cancel'"
                                color="primary"
                                class="mr-4"
                                outlined
                                tile
                                @click="reset()"
                            />
                        </v-layout>
                    </v-card-actions>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-global">
                <v-card class="mt-2">
                    <v-card-title>
                        {{ $t('product.tabs.global.headline') }}
                    </v-card-title>
                    <v-card-text>
                        <product-global-tech-form
                            ref="globalTechForm"
                            v-model="productData.base"
                            :readonly="isGlobalTechTabReadonly"
                        />
                    </v-card-text>
                    <v-card-actions v-if="!isGlobalTechTabReadonly">
                        <v-layout justify-end row>
                            <v-btn
                                v-t="'product.button.update'"
                                :disabled="isUpdatingProduct"
                                :loading="isUpdatingProduct"
                                color="primary"
                                class="mr-4"
                                tile
                                @click="update('global')"
                            />
                            <v-btn
                                v-t="'product.button.cancel'"
                                color="primary"
                                class="mr-4"
                                outlined
                                tile
                                @click="reset()"
                            />
                        </v-layout>
                    </v-card-actions>
                </v-card>
                <v-expansion-panels v-model="panel" :popout="someTableIsDirty" class="mt-2">
                    <product-table-panel
                        v-for="table in areas.globalTech"
                        :key="table"
                        :product="product"
                        :table-name="table"
                        :compare-mode="compareMode"
                        :readonly="isGlobalTechTabReadonly"
                        :disabled="someTableIsDirty"
                        @dirtyChanged="someTableIsDirty = $event"
                    />
                </v-expansion-panels>
            </v-tab-item>

            <v-tab-item value="tab-regulatory">
                <v-card class="mt-2">
                    <v-card-title>
                        {{ $t('product.tabs.regulatory.headline') }}
                    </v-card-title>
                </v-card>
                <v-expansion-panels v-model="panel" :popout="someTableIsDirty" class="mt-2">
                    <product-table-panel
                        v-for="table in areas.regulatory"
                        :key="table"
                        :product="product"
                        :table-name="table"
                        :compare-mode="compareMode"
                        :readonly="isRegulatoryTabReadonly || table === 'countryRegulation'"
                        :disabled="someTableIsDirty"
                        @dirtyChanged="someTableIsDirty = $event"
                    />
                </v-expansion-panels>
            </v-tab-item>

            <v-tab-item value="tab-marketing">
                <v-card class="mt-2">
                    <v-card-title>
                        {{ $t('product.tabs.marketing.headline') }}
                    </v-card-title>
                    <v-card-text class="pb-0">
                        <product-marketing-form
                            ref="globalMarketingForm"
                            v-model="productData.base"
                            :readonly="isMarketingTabReadonly"
                        />
                    </v-card-text>
                    <v-card-actions v-if="!isMarketingTabReadonly">
                        <v-layout justify-end row>
                            <v-btn
                                v-t="'product.button.update'"
                                :disabled="isUpdatingProduct"
                                :loading="isUpdatingProduct"
                                color="primary"
                                class="mr-4"
                                tile
                                @click="update('marketing')"
                            />
                            <v-btn
                                v-t="'product.button.cancel'"
                                color="primary"
                                class="mr-4"
                                outlined
                                tile
                                @click="reset()"
                            />
                        </v-layout>
                    </v-card-actions>
                </v-card>
                <v-expansion-panels v-model="panel" :popout="someTableIsDirty" class="mt-2">
                    <product-table-panel
                        v-for="table in areas.marketing"
                        :key="table"
                        :product="product"
                        :table-name="table"
                        :compare-mode="compareMode"
                        :readonly="isMarketingTabReadonly"
                        :disabled="someTableIsDirty"
                        @dirtyChanged="someTableIsDirty = $event"
                    />
                    <product-table-panel
                        v-for="table in areas.segmentedMarketing"
                        :key="table"
                        :product="product"
                        :table-name="table"
                        :compare-mode="compareMode"
                        :with-segments="restrictedSegments"
                        :readonly="isMarketingTabReadonly"
                        :outer-selected-marketing-segment="selectedMarketingSegment"
                        :disabled="someTableIsDirty"
                        @dirtyChanged="someTableIsDirty = $event"
                        @segmentIdChanged="onMarketingSegmentChange"
                    />
                </v-expansion-panels>
            </v-tab-item>

            <v-tab-item value="tab-communication">
                <v-card class="mt-2">
                    <v-card-title>
                        {{ $t('product.tabs.communication.headline') }}
                    </v-card-title>
                    <v-card-text>
                        <product-media-form
                            ref="mediaForm"
                            v-model="productData.base.mediaLinks"
                            :product-id="productData.base.id"
                            :readonly="isCommunicationTabReadonly"
                            :disabled="someTableIsDirty"
                            @dirtyChanged="someTableIsDirty = $event"
                        />
                    </v-card-text>
                    <v-card-actions v-if="!isCommunicationTabReadonly">
                        <v-layout justify-end row>
                            <v-btn
                                v-t="'product.button.update'"
                                :disabled="isUpdatingProduct"
                                :loading="isUpdatingProduct"
                                color="primary"
                                class="mr-4"
                                tile
                                @click="update('media')"
                            />
                            <v-btn
                                v-t="'product.button.cancel'"
                                color="primary"
                                class="mr-4"
                                outlined
                                tile
                                @click="reset()"
                            />
                        </v-layout>
                    </v-card-actions>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-segment">
                <v-card class="mt-2">
                    <v-card-title>
                        {{ $t('product.tabs.segment.headline') }}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="selectedSegment"
                                    :disabled="someTableIsDirty"
                                    :items="translatedSegments"
                                    :label="$t('product.segment.label')"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="selectedTechnology"
                                    :disabled="someTableIsDirty"
                                    :items="translatedTechnologies"
                                    :label="$t('product.technology.label')"
                                />
                            </v-col>
                        </v-row>
                        <v-dialog v-model="showTechnologiesDialog" max-width="550">
                            <template v-slot:activator="{ on }">
                                <v-flex text-right>
                                    <v-btn
                                        v-t="'product.ignoreTechnologies.activator'"
                                        :disabled="isMarketSegmentTabReadonly"
                                        color="primary"
                                        class=""
                                        text
                                        small
                                        v-on="on"
                                    />
                                </v-flex>
                            </template>
                            <v-card>
                                <v-card-title v-t="'product.ignoreTechnologies.headline'" />
                                <v-card-text>
                                    {{ $t('product.ignoreTechnologies.text') }}
                                    <v-row>
                                        <v-checkbox
                                            v-for="tech in translatedTechnologies"
                                            :key="tech.value"
                                            v-model="computedIrrelevantTechnologies"
                                            :label="tech.text"
                                            :value="tech.value"
                                            :disabled="disabledIrrelevantTechnologies.includes(tech.value)"
                                            class="mx-2"
                                            hide-details
                                            dense
                                        />
                                    </v-row>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <div class="flex-grow-1"></div>
                                    <v-btn
                                        v-t="'common.ok'"
                                        :disabled="
                                            computedIrrelevantTechnologies.length <=
                                            disabledIrrelevantTechnologies.length
                                        "
                                        color="primary"
                                        tile
                                        @click="markTechnologiesAsIrrelevant()"
                                    />
                                    <v-btn
                                        v-t="'common.cancel'"
                                        color="primary"
                                        text
                                        @click="closeTechnologiesDialog()"
                                    />
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="showTechnologiesConfirmation" max-width="500px">
                            <v-card>
                                <v-card-title v-t="'product.ignoreTechnologiesConfirmation.headline'" />
                                <v-card-text>{{ ignoreTechnologiesConfirmationText }}</v-card-text>
                                <v-card-actions>
                                    <div class="flex-grow-1"></div>
                                    <v-btn
                                        v-t="'common.yes'"
                                        color="primary"
                                        tile
                                        @click="markTechnologiesAsIrrelevant(true)"
                                    />
                                    <v-btn
                                        v-t="'common.no'"
                                        color="primary"
                                        text
                                        @click="showTechnologiesConfirmation = false"
                                    />
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card-text>
                </v-card>
                <v-expansion-panels v-model="panel" :popout="someTableIsDirty" class="mt-2">
                    <product-table-panel
                        v-for="table in areas.segmentTech"
                        :key="table"
                        :product="product"
                        :table-name="table"
                        :compare-mode="compareMode"
                        :segment-id="selectedSegment"
                        :technology-id="selectedTechnology"
                        :readonly="isMarketSegmentTabReadonly"
                        :disabled="someTableIsDirty"
                        @dirtyChanged="someTableIsDirty = $event"
                    />
                </v-expansion-panels>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { extractAndDisplayError } from '@/utils'
import ProductTablePanel from './ProductTablePanel'
import ProductBaseForm from './ProductBaseForm'
import ProductGlobalTechForm from './ProductGlobalTechForm'
import ProductMarketingForm from './ProductMarketingForm'
import ProductErrorBlock from './ProductErrorBlock'
import ProductMediaForm from './ProductMediaForm'

export default {
    name: 'ProductForm',
    components: {
        ProductTablePanel,
        ProductBaseForm,
        ProductGlobalTechForm,
        ProductMarketingForm,
        ProductErrorBlock,
        ProductMediaForm
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        compareMode: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedProductIndex: {
            type: Number,
            required: false,
            default: 0
        },
        selectedTab: {
            type: String,
            required: false,
            default: 'tab-marketing'
        },
        selectedPanel: {
            type: Number,
            required: false,
            default: null
        },
        outerSelectedSegment: {
            type: [String, Number],
            default: null
        },
        outerSelectedTechnology: {
            type: [String, Number],
            default: null
        },
        outerSelectedMarketingSegment: {
            type: [String, Number],
            default: null
        }
    },
    data: function () {
        return {
            productData: null,
            tab: null,
            panel: null,
            someTableIsDirty: false,
            selectedSegment: null,
            selectedTechnology: null,
            selectedMarketingSegment: null,
            selectedIrrelevantTechnologies: [],
            disabledIrrelevantTechnologies: [],
            showArchiveWarning: false,
            showTechnologiesConfirmation: false,
            showTechnologiesDialog: false
        }
    },
    computed: {
        ...mapState('product', [
            'tables',
            'rootSegments',
            'marketSegments',
            'technologies',
            'currentProducts',
            'isFetchingProduct',
            'isUpdatingProduct',
            'fetchProductError',
            'updateProductError'
        ]),
        ...mapGetters('product', ['areas']),
        ...mapGetters('auth', [
            'allowedActions',
            'restrictedProductGroups',
            'restrictedRootMarketSegments',
            'restrictedMarketSegments',
            'isAdministrator'
        ]),
        restrictedSegments() {
            if (this.isAdministrator) {
                return this.marketSegments
            } else if (
                this.editModeRequested &&
                this.allowedActions.EDIT_MARKETING_RATING &&
                this.marketSegments &&
                this.marketSegments.length
            ) {
                return this.marketSegments.filter(s => this.restrictedRootMarketSegments.indexOf(s.childOf) > -1)
            }
            return this.marketSegments
        },
        translatedSegments() {
            let segments = []
            if (this.isAdministrator) {
                segments = this.marketSegments
            } else if (
                this.editModeRequested &&
                this.allowedActions.EDIT_MARKET_SEGMENT_RATING &&
                this.restrictedMarketSegments &&
                this.restrictedMarketSegments.length
            ) {
                segments = this.marketSegments.filter(s => this.restrictedMarketSegments.indexOf(s.text) > -1)
            } else {
                segments = this.marketSegments
            }
            return segments.map(seg => {
                return {
                    value: seg.value,
                    text: this.$t(`product.marketSegments.${seg.text}`)
                }
            })
        },
        translatedTechnologies() {
            return this.technologies.map(tech => {
                return {
                    value: tech.value,
                    text: this.$t(`product.technologies.${tech.text}`)
                }
            })
        },
        ignoreTechnologiesConfirmationText() {
            const list = this.computedIrrelevantTechnologies.map(it => {
                return `"${this.translatedTechnologies.find(t => t.value === it).text}"`
            })
            return this.$t('product.ignoreTechnologiesConfirmation.text', { list: list.join(', ') })
        },
        productId() {
            return this.productData.base.id
        },
        productIsArchived() {
            return this.product && this.product.base.segmentation === 'CANCEL'
        },
        productHeadline() {
            if (!this.productData) {
                return ''
            }
            return this.productData.base.name + (this.productIsArchived ? ` - ${this.$t('product.archived')}` : '')
        },
        editModeRequested() {
            return !!(this.$route.query && this.$route.query.edit)
        },
        computedIrrelevantTechnologies: {
            set: function (val) {
                this.selectedIrrelevantTechnologies = val
            },
            get: function () {
                const allTechnologyIds = this.technologies.map(e => e.value)

                // initially asume all technology as irrelevant
                const technologyIrrelevantValues = {}
                allTechnologyIds.forEach(tecId => {
                    if (!technologyIrrelevantValues[tecId]) {
                        technologyIrrelevantValues[tecId] = true
                    }
                })

                const relevantTableData = []
                this.areas.segmentTech.forEach(key => {
                    if (
                        this.tables[key] &&
                        this.tables[key].inCompletenessCheck &&
                        key !== 'pigmentConcentratesCriteria'
                    ) {
                        const { data } = this.productData.tables[key]
                        relevantTableData.push(data ? data : {})
                    }
                })

                // iterate through all table date depending on the currently selected segment
                relevantTableData.forEach(data => {
                    if (data[this.selectedSegment]) {
                        allTechnologyIds.forEach(tecId => {
                            if (data[this.selectedSegment] && data[this.selectedSegment][tecId]) {
                                const tableDataForTech = Object.assign({}, data[this.selectedSegment][tecId])
                                // delete data from table which would interfere the calculation
                                delete tableDataForTech._isFilledCompletely
                                delete tableDataForTech._embedded
                                delete tableDataForTech._links
                                delete tableDataForTech.id
                                Object.keys(tableDataForTech).forEach(key => {
                                    if (
                                        tableDataForTech[key] !== 'NOT_APPLICABLE' &&
                                        technologyIrrelevantValues[tecId]
                                    ) {
                                        technologyIrrelevantValues[tecId] = false
                                    }
                                })
                            } else {
                                technologyIrrelevantValues[tecId] = false
                            }
                        })
                    } else {
                        Object.keys(technologyIrrelevantValues).forEach(
                            // eslint-disable-next-line no-return-assign
                            techId => (technologyIrrelevantValues[techId] = false)
                        )
                    }
                })

                const calculatedIrrelevantTechnologies = Object.keys(technologyIrrelevantValues)
                    .filter(key => technologyIrrelevantValues[key])
                    .map(el => parseInt(el, 10))

                this.setDisabledIrrelevantTechnologies(calculatedIrrelevantTechnologies)
                return Array.from(new Set(this.selectedIrrelevantTechnologies.concat(calculatedIrrelevantTechnologies)))
            }
        },
        //
        showAllTabs() {
            return this.isAdministrator || this.productIsArchived || !this.editModeRequested
        },
        showGlobalTechTab() {
            return this.allowedActions.EDIT_GLOBAL_TECHNICAL_RATING || this.showAllTabs
        },
        showRegulatoryTab() {
            return this.allowedActions.EDIT_REGULATORY_INFORMATION || this.showAllTabs
        },
        showMarketingTab() {
            return this.allowedActions.EDIT_MARKETING_RATING || this.showAllTabs
        },
        showCommunicationTab() {
            return this.allowedActions.EDIT_PICTURES_AND_VIDEOS || this.showAllTabs
        },
        showMarketSegmentTab() {
            return this.allowedActions.EDIT_MARKET_SEGMENT_RATING || this.showAllTabs
        },
        isBasicTabReadonly() {
            if (this.productIsArchived) {
                return true
            }
            if (this.isAdministrator) {
                return false
            }
            return !(this.editModeRequested && this.allowedActions.EDIT_BASIC_PRODUCT_INFORMATION)
        },
        isGlobalTechTabReadonly() {
            if (this.productIsArchived) {
                return true
            }
            if (this.isAdministrator) {
                return false
            }
            if (
                this.editModeRequested &&
                this.allowedActions.EDIT_GLOBAL_TECHNICAL_RATING &&
                this.restrictedProductGroups &&
                this.restrictedProductGroups.length
            ) {
                const group = this.product.base.productGroupWebsite
                return this.restrictedProductGroups.indexOf(group) < 0
            }
            return true
        },
        isRegulatoryTabReadonly() {
            if (this.productIsArchived || this.productData.base.regulatoryDataEditable === false) {
                return true
            }
            if (this.isAdministrator) {
                return false
            }
            return !(this.editModeRequested && this.allowedActions.EDIT_REGULATORY_INFORMATION)
        },
        isMarketingTabReadonly() {
            if (this.productIsArchived) {
                return true
            }
            return !(this.isAdministrator || (this.editModeRequested && this.allowedActions.EDIT_MARKETING_RATING))
        },
        isCommunicationTabReadonly() {
            if (this.productIsArchived) {
                return true
            }
            return !(this.isAdministrator || (this.editModeRequested && this.allowedActions.EDIT_PICTURES_AND_VIDEOS))
        },
        isMarketSegmentTabReadonly() {
            if (this.productIsArchived) {
                return true
            }
            return !(this.isAdministrator || (this.editModeRequested && this.allowedActions.EDIT_MARKET_SEGMENT_RATING))
        },
        allowSegmentationUnarchiveOnChange() {
            return (
                this.productIsArchived &&
                this.isAdministrator &&
                this.product.base.segmentation !== this.productData.base.segmentation
            )
        },
        showIsArchivedWarning() {
            return this.productIsArchived && !this.allowedActions.ACCESS_ARCHIVE
        }
    },
    watch: {
        product: {
            deep: true,
            handler: function (product) {
                this.productData = JSON.parse(JSON.stringify(product))
            }
        },
        panel: function (panelIndex) {
            this.$emit('selectPanel', panelIndex)
        },
        selectedPanel: {
            handler: function (panelIndex) {
                this.panel = panelIndex
            }
        },
        someTableIsDirty: {
            handler: function (isDirty) {
                this.$emit('transitTableIsDirty', {
                    productId: this.product.base.id,
                    isDirty
                })
            }
        },
        showTechnologiesDialog(val) {
            if (!val) {
                this.selectedIrrelevantTechnologies = []
            }
        },
        selectedSegment: function (segment) {
            this.$emit('segmentChanged', segment)
        },
        selectedTechnology: function (technology) {
            this.$emit('technologyChanged', technology)
        },
        selectedMarketing: function (marketing) {
            this.$emit('marketingChanged', marketing)
        },
        outerSelectedSegment: function (segment) {
            this.selectedSegment = segment
        },
        outerSelectedTechnology: function (technology) {
            this.selectedTechnology = technology
        },
        outerSelectedMarketingSegment: function (marketing) {
            this.selectedMarketingSegment = marketing
        }
    },
    mounted() {
        this.productData = JSON.parse(JSON.stringify(this.product))
        this.selectedSegment = this.translatedSegments.length ? this.translatedSegments[0].value : null
        this.selectedTechnology = this.technologies.length ? this.technologies[0].value : null
    },
    methods: {
        update(area, archiveWarningAccepted) {
            let updateData = null
            let action = 'product/updateProductBase'

            switch (area) {
                case 'base': {
                    if (!this.$refs.baseForm.validate()) {
                        return
                    }
                    const { id, name, msdsId, productGroupWebsite, segmentation } = this.productData.base

                    if (segmentation === 'CANCEL' && archiveWarningAccepted !== true) {
                        this.showArchiveWarning = true
                        return
                    }

                    updateData = { name, msdsId, productGroupWebsite, segmentation }
                    updateData.productId = id
                    break
                }
                case 'global': {
                    if (!this.$refs.globalTechForm.validate()) {
                        return
                    }
                    const { description } = this.productData.base
                    updateData = { description }
                    break
                }
                case 'media': {
                    if (!this.$refs.mediaForm.validate()) {
                        return
                    }
                    updateData = this.$refs.mediaForm.rawMediaLinks
                    action = 'product/updateMediaLinks'
                    break
                }
                case 'marketing': {
                    const { displayOnCoatinoWebsite } = this.productData.base
                    updateData = { displayOnCoatinoWebsite }
                    break
                }
                default:
                    break
            }
            if (!updateData) {
                return
            }
            this.$store
                .dispatch(action, { updateData, productId: this.productId })
                .then(() => {
                    this.$noty.success(this.$t('product.updateProduct.successMessage'), { timeout: 2000 })
                    if (updateData.segmentation && updateData.segmentation === 'CANCEL') {
                        this.$router.replace({ name: 'home' })
                    }
                    if (area === 'media') {
                        this.$refs.mediaForm.onUpdate()
                    }
                })
                .catch(err => extractAndDisplayError(err, this.$noty))
        },
        reset() {
            this.productData.base = Object.assign({}, this.product.base)
            this.$refs.mediaForm.onReset()
            this.$nextTick(() => this.$refs.baseForm.validate())
        },
        closeTechnologiesDialog() {
            this.showTechnologiesDialog = false
        },
        markTechnologiesAsIrrelevant(warningAccepted) {
            if (!warningAccepted) {
                this.showTechnologiesConfirmation = true
                return
            }
            this.$store
                .dispatch('product/markTechnologiesIrrelevant', {
                    segmentId: this.selectedSegment,
                    technologyIds: this.computedIrrelevantTechnologies,
                    productId: this.productId
                })
                .then(() => {
                    this.$store
                        .dispatch('product/fetchCurrentProducts')
                        .catch(err => extractAndDisplayError(err, this.$noty))
                    this.$noty.success(this.$t('product.updateProduct.successMessage'), { timeout: 2000 })
                    this.showTechnologiesDialog = false
                    this.showTechnologiesConfirmation = false
                })
                .catch(err => extractAndDisplayError(err, this.$noty))
        },
        onMarketingSegmentChange(val) {
            this.$emit('marketingSegmentChanged', val)
        },
        setDisabledIrrelevantTechnologies(calculatedIrrelevantTechnologies) {
            this.disabledIrrelevantTechnologies = calculatedIrrelevantTechnologies
        }
    }
}
</script>

<style>
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
