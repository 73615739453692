<template>
    <v-card v-if="productData" height="100%">
        <v-flex d-flex justify-space-between>
            <v-card-title class="primary--text">{{ productHeadline }}</v-card-title>
            <v-btn v-if="compareMode" text icon class="ma-2" @click="closeProduct">
                <v-icon>{{ svgClose }}</v-icon>
            </v-btn>
        </v-flex>
        <v-card-text>{{ productData.base.description }}</v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
    name: 'ProductHeader',
    props: {
        product: {
            type: Object,
            required: true
        },
        compareMode: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            productData: null,
            svgClose: mdiClose
        }
    },
    computed: {
        ...mapState('product', ['currentProducts']),
        currentProduct() {
            return this.currentProducts[this.selectedProductIndex]
        },
        productHeadline() {
            if (!this.productData) {
                return ''
            }
            return this.productData.base.name + (this.productIsArchived ? ` - ${this.$t('product.archived')}` : '')
        },
        productIsArchived() {
            return this.productData && this.productData.base.segmentation === 'CANCEL'
        }
    },
    watch: {
        product: {
            deep: true,
            handler: function (newValue) {
                this.productData = JSON.parse(JSON.stringify(newValue))
            }
        }
    },
    mounted() {
        this.productData = JSON.parse(JSON.stringify(this.product))
    },
    methods: {
        closeProduct() {
            this.$store.commit('product/closeProduct', { productId: this.product.base.id })
        }
    }
}
</script>
