<template>
    <v-dialog v-model="showApplyMediaLinkDialog" max-width="550">
        <template v-slot:activator="{ on }">
            <v-btn
                class="apply-media-link-button"
                :disabled="!productId || disabled"
                text
                :color="isCompareMode ? 'grey' : 'primary'"
                :icon="isCompareMode"
                width="100%"
                height="100%"
                style="white-space: normal"
                v-on="on"
            >
                <div v-if="!isCompareMode">{{ $t('product.media.applyMediaLinksButton') }}</div>
                <v-tooltip v-else top>
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn text icon v-on="onTooltip">
                            <v-icon>{{ svgApply }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('product.media.applyMediaLinksButton') }}</span>
                </v-tooltip>
            </v-btn>
        </template>
        <v-card>
            <v-card-title v-t="'product.media.applyMediaLinksDialogHeadline'" />
            <v-card-text>
                {{ $t('product.media.applyMediaLinksDialogText', { productGroup: getProductGroupName() }) }}
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn v-t="'common.yes'" color="primary" tile @click="assignMediaLink()" />
                <v-btn v-t="'common.no'" color="primary" text @click="closeApplyMediaLinksDialog()" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { extractAndDisplayError } from '../utils'
import { mapState } from 'vuex'
import { mdiGoogleCirclesGroup } from '@mdi/js'

export default {
    name: 'MediaLinkAssignButton',
    props: {
        productId: {
            type: Number,
            required: true
        },
        mediaLinkId: {
            type: Number,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: function () {
        return {
            showApplyMediaLinkDialog: false,
            svgApply: mdiGoogleCirclesGroup
        }
    },
    computed: {
        ...mapState('product', ['productGroups', 'currentProducts']),
        isCompareMode: state => {
            return state.currentProducts && state.currentProducts.length > 1
        }
    },
    methods: {
        assignMediaLink() {
            this.$store
                .dispatch('product/assignMediaLinkToAllProductsOfGroup', {
                    productId: this.productId,
                    mediaLinkId: this.mediaLinkId
                })
                .then(() => {
                    this.$noty.success(this.$t('product.updateProduct.successMessage'), { timeout: 2000 })
                })
                .catch(err => extractAndDisplayError(err, this.$noty))
            this.closeApplyMediaLinksDialog()
        },
        closeApplyMediaLinksDialog() {
            this.showApplyMediaLinkDialog = false
        },
        getProductGroupName() {
            const currentProduct = this.currentProducts.find(el => el.base.id === this.productId)
            const value = currentProduct.base.productGroupWebsite
            return this.productGroups.find(el => el.value === value).text
        }
    }
}
</script>

<style>
.apply-media-link-button .v-btn__content {
    max-width: 100%;
}
</style>
