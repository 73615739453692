<template>
    <v-card color="error" tile dark max-width="600" class="mx-auto">
        <v-card-text class="white--text text-center">
            <div v-t="'product.fetchingError.headline'" class="headline mb-2" />
            <div v-t="'product.fetchingError.text'" class="centered--text" />
            <div v-t="'product.fetchingError.detailsBelow'" class="centered--text" />
        </v-card-text>
        <v-card-actions>
            <v-btn icon class="mx-auto" @click="showErrorDetails = !showErrorDetails">
                <v-icon>{{ showErrorDetails ? svgChevronUp : svgChevronDown }}</v-icon>
            </v-btn>
        </v-card-actions>
        <v-expand-transition>
            <div v-show="showErrorDetails">
                <v-card-text class="white--text" v-html="errorDetails" />
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'

export default {
    name: 'ProductErrorBlock',
    props: {
        error: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        showErrorDetails: false,
        svgChevronDown: mdiChevronDown,
        svgChevronUp: mdiChevronUp
    }),
    computed: {
        errorDetails() {
            const msg = [`${this.error.status} - ${this.error.statusText}`]
            if (this.error.errorMessage) {
                msg.push(this.error.errorMessage)
            } else if (this.error.data && this.error.data.message) {
                msg.push(this.error.data.message)
            }
            return msg.join('<br>')
        }
    }
}
</script>
