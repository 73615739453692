<template>
    <v-layout column>
        <v-flex>
            <v-card class="mb-4">
                <v-card-title v-t="'product.createProduct.headline'" class="primary--text" />
            </v-card>

            <v-card>
                <v-card-text>
                    <product-base-form ref="baseForm" v-model="productData" :is-create="true" :readonly="false" />
                </v-card-text>
                <v-card-actions>
                    <v-layout justify-end row>
                        <v-btn
                            v-t="'product.button.createProductWithWorkflow'"
                            :disabled="isSavingProduct"
                            :loading="isSavingProduct"
                            color="primary"
                            class="mr-4"
                            tile
                            @click="create"
                        />
                        <v-btn
                            v-t="'product.button.cancel'"
                            color="primary"
                            class="mr-4"
                            outlined
                            tile
                            @click="cancel"
                        />
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { extractAndDisplayError } from '@/utils'
import ProductBaseForm from '../components/ProductBaseForm'

export default {
    name: 'CreateProduct',
    components: {
        ProductBaseForm
    },
    data: () => ({
        productData: {
            name: '',
            id: null,
            msdsId: null,
            productGroupWebsite: null,
            description: '',
            displayOnCoatinoWebsite: false
        }
    }),
    computed: {
        ...mapState('product', ['isSavingProduct'])
    },
    watch: {
        productData: {
            handler: function (newValue) {
                this.$store.commit('product/setCreateProductData', newValue)
            },
            deep: true
        }
    },
    methods: {
        create() {
            if (!this.$refs.baseForm.validate()) {
                return
            }
            this.$store
                .dispatch('product/createProduct')
                .then(item => {
                    this.$noty.success(this.$t('product.createProduct.successMessage'), { timeout: 2000 })
                    this.$router.push({ name: 'product', params: { id: item.id }, query: { edit: '1' } })
                })
                .catch(err => extractAndDisplayError(err, this.$noty))
        },
        cancel() {
            this.productData = {}
            this.$router.push({ name: 'home' })
        }
    }
}
</script>
